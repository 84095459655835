import { useUserGetProfile } from "modules/User/userApi";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuthStore } from "./authStore";
import { trackEvent } from "lib/amplitude";
import { useAuthLogoutApi, useAuthMoodleApi } from "modules/Auth/authApi";
import { deleteCookie, getCookie, setCookie } from "lib/helper";
import { getGlobalStateContext } from "lib/global";
import { isGateURL } from "lib/host";

// only called once, in root,
// if token exist in localstorage => get user profile => login
// subscribe event token change, if token is empty => logout,
// event token change coming from apiFetcher middleware interceptor,
// if apiFetcher return 401, delete token in localstorage, publish event to here
export const useAuthInit = () => {
  const { setNotLogin, setLogin, setLoading } = useAuthStore();
  
  const userGetProfile = useUserGetProfile();

  useEffect(() => {
    const fetchGetUserProfile = () => {
      !userGetProfile.data ? setLoading() : {};

      userGetProfile.initialFetch(
        {},
        {
          onSuccess(res) {
            const currentUser = JSON.stringify(res.data)
            setLogin(res.data);
          },
          onError(err) {
            if (err.status === 401) {
              setNotLogin();
              localStorage.removeItem("token");
            }
          },
        }
      );
    };

    if (localStorage.getItem("token")) {
      fetchGetUserProfile();
    } else {
      setNotLogin();
    }

    const handleTokenChange = () => {
      try {
        if (!window.localStorage.getItem("token")) {
          setNotLogin();
        }
      } catch (err) {
        console.error(err);
      }
    };

    window.addEventListener("tokenChange", handleTokenChange);
    return () => {
      window.removeEventListener("tokenChange", handleTokenChange);
    };
  }, []);
};

// this hooks is for preventing access into page when user already login (have token)
// when user have token, redirect to home "/",
// for example, use this in Login page / register page
export const useAuthRedirectHomeWhenLogin = () => {
  const router = useRouter();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (router.pathname !== "/") {
        router.replace("/");
      }
    }
  }, []);
};

// this hooks is for preventing access into page when user is not login (dont have token),
// forcing the user to login first, then redirect again to previous url
// when user dont have token, redirect to login "/login",
// if login is success, user will redirected into `previousCurrentRoute`
export const useAuthForceLogin = () => {
  const router = useRouter();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      if (router.pathname !== "/login") {
        const currentPath = router.asPath;
        router.replace({
          pathname: "/login",
          query: {
            url: encodeURIComponent(currentPath),
          },
        });
      }
    }
  }, []);
};

// for redirect to previous url, used in login / register page
export const useRedirectPreviousPath = () => {
  const router = useRouter();

  const url =
    typeof router.query.url === "string" ? router.query.url : undefined;

  const previousPath =
    typeof url === "string" ? (decodeURIComponent(url as string) as any) : "/";

  function redirectToPreviousPath() {
    
    if (previousPath) {
      if(router.pathname == "/course/[courseSlug]"){
        // This one to allow HRIS EATS to use
        // autologin URL in LMS screen
        // so no need redirect to previous path
      }else{
        router.replace(previousPath);
      }
    } else {
      router.replace("/");
    }
  }

  function redirectToQueryPath({ type = "course", slug, ...rest }) {
    let url = type == "course" ? "/course/[courseSlug]" : "/";

    if (type && slug) {
      //@ts-ignore
      router.replace({
        pathname: url,
        query: {
          ...rest,
          courseSlug: slug,
        },
      });
    }
  }

  return {
    encodedPath: url,
    previousPath,
    redirectToPreviousPath,
    redirectToQueryPath,
  };
};

export const useAuthSetLogin = () => {
  const authStore = useAuthStore();

  const userGetProfile = useUserGetProfile();

  const { redirectToPreviousPath, redirectToQueryPath } =
    useRedirectPreviousPath();

  const setLogin = (
    token: string,
    methodLogin: string,
    trackId: any,
    additionalQueries?: any,
    domain?: string
  ) => {
    localStorage.setItem("token", token);
      
    if(domain) setCookie("token", token, 7, domain)
    setCookie("token", token, 7)

    authStore.setLoading();
    userGetProfile.doFetch(
      {},
      {
        onSuccess(res) {
          authStore.setLogin(res.data);

          if (methodLogin) {
            trackEvent({
              event: trackId,
              property: {
                method: methodLogin,
              },
            });
          }
        },
      }
    );

    if (additionalQueries) {
      redirectToQueryPath(additionalQueries);
    } else {
      redirectToPreviousPath();
    }
  };

  return setLogin;
};

export const useLoginByMoodle = () => {
  const router = useRouter();
  const authStore = useAuthStore();
  const authMoodleApi = useAuthMoodleApi();
  const userGetProfile = useUserGetProfile();
  const authSetLogin = useAuthSetLogin();

  const { redirectToPreviousPath, redirectToQueryPath } =
    useRedirectPreviousPath();

  const queries = Object.fromEntries(
    Object.entries(router.query).filter(([key]) =>
      ["token", "referral", "email", "name", "parent", "role", "sortBy", "institutionKey", "identity_number", "employee_id_number", "work_unit", "position","timestamp"].includes(key)
    )
  );

  const queriesCourse = Object.fromEntries(
    Object.entries(router.query).filter(([key]) =>
      ["type", "slug", "usePreview"].includes(key)
    )
  );

  const queriesLogin = Object.fromEntries(
    Object.entries(router.query).filter(([key]) =>
      ["auto_login", "autoLogin"].includes(key)
    )
  );

  const fetchGetUserProfile = () => {
    !userGetProfile.data ? authStore.setLoading() : {};

    userGetProfile.initialFetch(
      {},
      {
        onSuccess(res) {
          const token = localStorage.getItem("token")
          authStore.setLogin(res.data);
        },
        onError(err) {
          authStore.setNotLogin();
          localStorage.removeItem("token");
        },
      }
    );
  };
  
  const updateLogin = () => {
    authStore.setNotLogin();
    deleteCookie("token");
    localStorage.removeItem("token");

    // Allowing subdomain user to see this whitelist path
    const whitelistPath = ["/terms-of-use", "/privacy-policy", "/register"];
    const isGate = isGateURL(window.location.hostname)

    // Allow gate to enter course anonymously for mobile
    if(!isGate && !whitelistPath.includes(router.pathname)){
      // if (!whitelistPath.includes(router.pathname)) {
      //   // router.replace("/login");
      // }
    }else{
      // console.log("router.pathname:",router.pathname)
      if(router.pathname !== "/course/[courseSlug]"){
        router.replace("/login");
      }
    }
  };

  useEffect(() => {
    if (
      (Object.keys(router.query).includes("auto_login") || Object.keys(router.query).includes("autoLogin")) &&
      Object.entries(queries)
    ) {
      authStore.setLoading();
      authMoodleApi.doFetch(
        //@ts-ignore
        {
          ...queries,
        },
        {
          onSuccess(data) {
            //@ts-ignore
            authSetLogin(
              data?.token,
              "moodle",
              "signin_success_a",
              queriesCourse,
              "domain=gokampus.com"
            );
          },
        }
      );
      redirectToPreviousPath();
    }  
    // else if (Object.entries(queries)) {
    //   fetchGetUserProfile();
    //   //@ts-ignore
    //   redirectToQueryPath({ ...queriesCourse });
    // } 
    else {
      if (localStorage.getItem("token")) {
        fetchGetUserProfile();
      } else {
        authStore.setNotLogin();
      }
  
      const handleTokenChange = () => {
        try {
          if (!window.localStorage.getItem("token")) {
            authStore.setNotLogin();
          }
        } catch (err) {
          console.error(err);
        }
      };
  
      window.addEventListener("tokenChange", handleTokenChange);
      return () => {
        window.removeEventListener("tokenChange", handleTokenChange);
      };
    }
  }, []);
};


export const useInitialNewLogin = () => {
  const globalState = getGlobalStateContext();
  const authLogoutApi = useAuthLogoutApi();
  const authStore = useAuthStore();

  const lclToken = typeof window !== 'undefined' && localStorage.getItem("token");
  const cookieToken = typeof window !== 'undefined' && getCookie("token");
  const storageVersion = typeof window !== 'undefined' && localStorage.getItem("gk-version");
  

  if(lclToken) {
    if(!cookieToken || !storageVersion) {
      authLogoutApi.initialFetch({});
      localStorage.removeItem("token");
      localStorage.removeItem("gk-version");
      deleteCookie("token")
      authStore.setNotLogin();
    }

    if(cookieToken && (storageVersion !== globalState?.version)) {
      authLogoutApi.initialFetch({});
      localStorage.removeItem("token");
      localStorage.removeItem("gk-version");
      deleteCookie("token")
      authStore.setNotLogin();
    } 
  }
};


export const useAuthNewInit = () => {
  const { setNotLogin, setLogin, setLoading } = useAuthStore();
  
  const userGetProfile = useUserGetProfile();

  useEffect(() => {
    const fetchGetUserProfile = () => {
      !userGetProfile.data ? setLoading() : {};

      userGetProfile.initialFetch(
        {},
        {
          onSuccess(res) {
            setLogin(res.data);
          },
          onError(err) {
            if (err.status === 401) {
              setNotLogin();
              localStorage.removeItem("token");
            }
          },
        }
      );
    };

    if (localStorage.getItem("token")) {
      fetchGetUserProfile();
    } else {
      setNotLogin();
    }

    const handleTokenChange = () => {
      try {
        if (!window.localStorage.getItem("token")) {
          setNotLogin();
        }
      } catch (err) {
        console.error(err);
      }
    };

    window.addEventListener("tokenChange", handleTokenChange);
    return () => {
      window.removeEventListener("tokenChange", handleTokenChange);
    };
  }, []);
};
