export const DEFAULT = {
  title: "goKampus - Akses 1.000+ Kursus dari Perusahaan Terbaik Dunia",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://www.gokampus.com/",
  image: "/icon-default.png",

  // for app (PWA)
  appTitle: "goKampus",
  type: "article",
};

export const DEFAULTBUSINESS = {
  title: "Dapatkan Akses 1.000+ Kursus di LMS/HRIS Perusahaan Anda",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://business.gokampus.com/",
  image: "/icon-default.png",

  // for app (PWA)
  appTitle: "goKampus Business",
  type: "website",
};

export const DEFAULTGATE = {
  title: "Dapatkan Akses 1.000+ Kursus di LMS/HRIS Perusahaan Anda",
  description: "On-demand streaming platform for continuous learning",
  themeColor: "#551FC1",
  url: "https://gate.gokampus.com/",
  image: "https://gate.gokampus.com/icon-default-business.png",

  // for app (PWA)
  appTitle: "goKampus Business",
  type: "website",
};

// Desa-desa
export const DEFAULTGATEMALUKU = {
  title:
    "goKampus Maluku - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://maluku-gate.gokampus.com/",
  image: "https://maluku-gate.gokampus.com/maluku/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Maluku",
  type: "website",
};

export const DEFAULTGATETRENGGALEK = {
  title:
    "goKampus Trenggalek - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://trenggalekkab.gokampus.com/",
  image: "https://trenggalekkab.gokampus.com/trenggalek/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Trenggalek",
  type: "website",
};
export const DEFAULTGATEPESAWARAN = {
  title:
    "goKampus Pesawaran - Belajar Berbagai Kursus Pengembangan Diri dan Tren Industri Terkini",
  description:
    "Platform Kursus Online dari Institusi, Perusahaan, dan Universitas Terkemuka",
  themeColor: "#551FC1",
  url: "https://pesawarankab.gokampus.com/",
  image: "https://pesawarankab.gokampus.com/pesawaran/icon-512x512.png",

  // for app (PWA)
  appTitle: "goKampus Pesawaran",
  type: "website",
};
