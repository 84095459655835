import { IconCheck } from "components/Icon/IconCheck";
import IconClose from "components/Icon/IconClose";
import { ToastType, useToastStore } from "./toastStore";

const styles: Record<ToastType, string> = {
  success: "bg-green-10 text-green-70 border-green-70 !fill-green-70",
  info: "bg-purple-10 text-purple-70 border-purple-70 !fill-purple-70",
  danger: "bg-red-10 text-red-70 border-red-70 !fill-red-70",
};

const iconColor: Record<ToastType, string> = {
  success: "#318A4E",
  info: "#2A1060",
  danger: "#D0375C",
};

export const ToastWrapper: React.FC<{}> = () => {
  const currentToast = useToastStore((state) => state.current);
  const hideAllToast = useToastStore((state) => state.hideAllToast);

  return (
    <div
      className={`fixed w-full flex justify-center transition-all duration-200 ease-in ${
        currentToast ? "top-0 opacity-100" : "-top-60 opacity-0"
      }`}
      style={{
        zIndex: 9999,
      }}
    >
      <div className="p-16 w-full max-w-[480px] h-fit">
        <div
          className={`rounded-4 h-full flex w-full items-center border p-16 gap-8 ${
            styles[currentToast?.type ?? "success"]
          }`}
        >
          {currentToast?.type === "success" ? (
            <IconCheck size={16} color={iconColor.success} />
          ) : null}
          <div className="flex-1 text-c1 font-medium">
            {currentToast?.message}
          </div>
          <div className="cursor-pointer" onClick={() => hideAllToast()}>
            <IconClose
              size={16}
              color={iconColor[currentToast?.type ?? "info"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
