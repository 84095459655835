import Head from "next/head";
import { DEFAULT, DEFAULTBUSINESS } from "./metaData";
import { useIsBusiness, useIsSubdomain, useIsTenant } from "lib/host";

type SocialMetaProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
};

export const SocialMeta = (props: SocialMetaProps) => {
  const isB2B = useIsTenant() || useIsSubdomain();
  const isBusiness = !isB2B;
  const isTenant = useIsSubdomain();
  const defaultMeta = isBusiness
    ? DEFAULTBUSINESS
    : isTenant
    ? { ...DEFAULT, url: window.location.href }
    : DEFAULT;

  const META = {
    title: props.title ?? defaultMeta.title,
    description: props.description ?? defaultMeta.description,
    url: props.url ?? defaultMeta.url,
    image: props.image ?? defaultMeta.image,
    type: props.type ?? defaultMeta.type,
  };

  return (
    <Head>
      <title>{META.title}</title>
      <meta
        name="description"
        content={META.description}
        itemProp="description"
      />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {/* <!-- Primary Meta Tags --> */}

      <link rel="canonical" href={META.url} />
      <link rel="image_src" href={META.image} />

      {/*  <!-- Open Graph / Facebook / LinkedIn --> */}
      <meta property="og:type" content={META.type} />
      <meta property="og:url" content={META.url} />
      <meta property="og:title" content={META.title} />
      <meta property="og:description" content={META.description} />
      <meta property="og:site_name" content={defaultMeta.appTitle} />
      <meta property="og:image" content={META.image} />

      <meta property="og:image" itemProp="image" content={META.image} />

      <meta content={META.description} itemProp="headline" />

      <meta name="keywords" content={META.description} itemProp="keywords" />

      <meta name="thumbnailUrl" content={META.image} itemProp="thumbnailUrl" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={META.url} />
      <meta property="twitter:title" content={META.title} />
      <meta property="twitter:description" content={META.description} />
      <meta property="twitter:image" content={META.image} />
    </Head>
  );
};
