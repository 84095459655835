import { createContext, useContext } from "react";
import { CONST } from "./const";

// custom subdomain based on request

export const isTenantURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[1];

  return typeof subdomain == "string" && subdomain == "tenant";
};

export const IsTenantContext = createContext(false);

export const useIsTenant = () => {
  return useContext(IsTenantContext);
};

export const isBusinessURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  return typeof subdomain == "string" && subdomain.includes("business");
};

export const IsBusinessContext = createContext(false);

export const useIsBusiness = () => {
  return useContext(IsBusinessContext);
};

export const isGateURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = CONST.IS_DEV ? "ares-gate" : "gate";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMalukuURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = CONST.IS_DEV ? "ares-maluku-gate" : "maluku-gate";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isTrenggalekURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "trenggalekkab";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isPesawaranURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "pesawarankab";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const IsGateContext = createContext(false);
export const IsGateProvinceContext = createContext(false);

export const useIsGate = () => {
  return useContext(IsGateContext);
};

export const useIsGateProvince = () => {
  return useContext(IsGateProvinceContext);
};

export const getSubDomain = (hostname: string) => {
  return hostname.split(".")[0] ?? "";
};

export const IsSubdomainContext = createContext(false);

export const useIsSubdomain = () => {
  return useContext(IsSubdomainContext);
};
